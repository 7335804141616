<template>
    <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;" v-if="dealerGroups">
        <div>
            <Breadcrumbs class="inlineblock" />
        </div>

        <!-- RIGHT CLICK MENU -->
        <RenameDeleteActions
            v-if="showMenu"
            v-model="showMenu"

            :x="x"
            :y="y"

            :renameAction="renameGroup"
            :renameName="$t('general.rename')"
            :deleteAction="confirmDelete"
            :deleteName="$t('general.delete')"
        />
        <!-- RIGHT CLICK MENU -->

        <!-- ADD NEW DEALER GROUP DIALOG -->
        <NamingDialog
            v-if="isAddingGroup"
            v-model="isAddingGroup"

            :headlineName="$t('group.add_dealer_group')"
            :typeName="$t('general.name')"

            :cancelName="$t('general.cancel')"                
            :cancelFunction="() => (isAddingGroup = false)"
            :actionName="$t('general.add')"
            :actionFunction="confirmAddGroup"
        />
        <NamingDialog
            v-if="isEditingGroup"
            v-model="isEditingGroup"

            :baseValue="editedGroup.name"

            :headlineName="$t('group.rename_dealer_group') + ' ' + editedGroup.name"
            :typeName="$t('general.name')"

            :cancelName="$t('general.cancel')"                
            :cancelFunction="() => (isEditingGroup = false)"
            :actionName="$t('general.rename')"
            :actionFunction="saveGroup"
        />
        <!-- RENAME DEALER GROUP DIALOG -->

        <!-- DEALER GROUPS LIST -->
        <v-container fluid>
            <v-row>
                <v-col sm="6" cols="12">
                    <v-card class="align-center pa-5">
                        <v-row v-if="selectedGroupId != -1">
                            <v-spacer/>
                                <v-btn
                                rounded
                                small
                                color="primary"
                                dark
                                style="text-transform:none"
                                @click="renameGroup"
                            >
                            {{ $t('general.rename') }}
                            </v-btn>
                            <v-btn
                                style="text-transform:none"
                                rounded
                                small
                                color="red"
                                dark
                                @click="confirmDelete"
                            >
                            {{ $t('general.delete') }}
                            </v-btn>
                        </v-row>
                        <v-row>
                            <v-subheader>{{ $t("group.group_dealer") }}</v-subheader>
                            <v-list dense style="width:100%;max-height:60vh;overflow:auto;">
                                <v-list-item
                                    v-for="(item, i) in dealerGroups.filter((pt) => pt.id != -1)"
                                    :class="{
                                        'active-dealerGroups': item.id == selectedGroupId,
                                    }"
                                    :key="i"
                                    @click="setSelected(item.id)"
                                    @contextmenu="show($event, item)"
                                >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-row>
                        <v-row>
                            <v-btn
                            @click="add"
                            class="ma-2 add-pt-btn"
                            rounded
                            color="primary"
                            dark
                            >{{ $t("group.add_dealer_group") }}</v-btn>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col sm="6" cols="12">
                    <v-card class="align-center pa-5" v-if="selectedGroup">
                        <v-subheader style="font-size: 0.75rem">{{
                            $t("group.group_dealer")
                        }}</v-subheader>

                        <div v-for="(dealer, index) in selectedGroup.dealers" :key="'B'+ index">
                            <v-row>
                                <v-col cols="18" style="line-height:51px;padding-top:0px;padding-bottom:0px;">
                                {{ dealer.name }}
                                </v-col>
                                <v-col cols="1">
                                <v-icon v-on:click="removeDealer(index)">mdi-close</v-icon>
                                </v-col>
                            </v-row>
                        </div>
                        <v-select
                            dense
                            :items="availableDealers"
                            :label="$t('group.add_dealer_to_group')"
                            item-text="name"
                            item-value="id"
                            v-model="dealerAddId"
                            style="margin-top:10px;"
                            @change="dealerAdded()"
                        ></v-select>
                    
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- DEALER GROUPS LIST -->

        <ConfirmDialog
            v-if="confirmDialog"
            v-model="confirmDialog"

            :title="$t('confirm.head')"
            :description="$t('confirm.delete_group')"

            :cancelName="$t('reportInfo.no')"
            :actionName="$t('reportInfo.yes')"
            :actionFunction="deleteGroup"
        />
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs";
import RenameDeleteActions from "@/components/actions/RenameDeleteActions.vue";
import NamingDialog from '@/components/dialogs/NamingDialog.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import UserService from "@/services/user.service";

export default {
    name: "Groups",
    components: {
        Breadcrumbs,
        RenameDeleteActions,
        NamingDialog,
        ConfirmDialog
    },
    data() {
        return {
            x: 0,
            y: 0,
            dealerGroups: null,
            availableDealers: [],
            selectedDealerGroup: null,
            isEditingGroup: false,
            isEditingGroupInfo: false,
            showMenu: false,
            selectedGroupId: -1,
            selectedGroup: null,
            editedGroup: null,
            editedGroupInfo: null,
            isAddingGroup: false,
            dealerAddId: null,
            headers: [
                {
                text: "Name",
                value: "name",
                width: "50%",
                },
                { text: "Mobility", value: "mobility" },
                { text: "Form", value: "form" }
            ],
            confirmDialog: false,
        }
    },
    computed: {
        ...mapState({
            isMobile: (state) => state.isMobile,
            drawer: (state) => state.drawer,
        })
    },
    mounted() {
        console.log("tried mounted")
        this.setBreadcrumbs([
        { text: this.$t("general.settings"), href: "/settings" },
        { text: this.$t("group.group_dealer"), href: "/settings/groups" },
        ]);
        
        this.getDealerGroups(this.$i18n.locale);
    },
    methods: {
        ...mapMutations([
            'openToast',
        ]),
        ...mapActions({
            setBreadcrumbs: "setBreadcrumbs",
        }),
        show(e, item) {
            e.preventDefault();
            this.selectedGroup = item;
            this.showMenu = false;
            this.x = e.clientX;
            this.y = e.clientY;
            this.$nextTick(() => {
                this.showMenu = true;
            });
        },
        setSelected(id) {
            this.selectedGroup = this.dealerGroups.find(x => x.id == id);
            this.updateAvailableDealers(this.selectedGroup);
            this.selectedGroupId = id;
        },
        renameGroup() {
            this.isEditingGroup = true;
            this.editedGroup = JSON.parse(JSON.stringify(this.selectedGroup));
        },
        confirmDelete() {
            this.confirmDialog = true;
        },
        add() {
            this.isAddingGroup = true;
        },
        confirmAddGroup(newGroupName) {
            this.isAddingGroup = false;
            
            this.addDealerGroup({ groupName: newGroupName }).then(() => {
                let newGroup = this.dealerGroups.find(g => g.name == newGroupName);
                if (newGroup) {
                    this.setSelected(newGroup.id);
                }
                this.$forceUpdate();
            });
        },
        saveGroup(newGroupName) {
            let changes = {
                changes: {
                    dealerGroupId: this.editedGroup.id,
                    name: newGroupName,
                },
            };

            this.selectedGroup.name = newGroupName;

            this.saveDealerGroup(changes).then(() => {
                this.isEditingGroup = false;
            });
        },
        dealerAdded() {
            if (!this.dealerAddId) {
                return;
            }
            let changes = {
                changes: {
                    dealerGroupId: this.selectedGroup.id,
                    newDealerId: this.dealerAddId
                },
            };
            let dealerSelected = this.availableDealers.find(d => d.id == this.dealerAddId);
            this.selectedGroup.dealers.push(dealerSelected);
            this.updateAvailableDealers(this.selectedGroup);

            this.saveDealerGroup(changes).then(() => {
                this.isEditingGroup = false;
                this.dealerAddId = null;
                this.$forceUpdate();
            });
        },
        removeDealer(index) {
            let removedDealer = this.selectedGroup.dealers[index];
            let changes = {
                changes: {
                    dealerGroupId: this.selectedGroup.id,
                    dealerDeletedId: removedDealer.id
                },
            };

            this.selectedGroup.dealers.splice(index, 1);
            this.updateAvailableDealers(this.selectedGroup);

            this.saveDealerGroup(changes).then(() => {
                this.isEditingGroup = false;
                // scope.setSelected();
            });

        },
        deleteGroup() {
            this.deleteDealerGroup(this.selectedGroup).then(() => {
                this.setSelected(this.dealerGroups[0].id);
                this.$forceUpdate();
            });

            this.confirmDialog = false;
        },
        async getDealerGroups(){
            let response = await UserService.getDealerGroups();
            this.SET_DEALER_GROUPS(response.data);
        },
        async addDealerGroup(data) {
            let response = await UserService.addDealerGroup(data);
            this.SET_DEALER_GROUPS(response.data);
        },
        async deleteDealerGroup(data) {
            try {
                let response = await UserService.deleteDealerGroup(data);
                this.SET_DEALER_GROUPS(response.data);
                this.openToast({ message: this.$t("group.dealer_group_deleted"), duration: 5000, color: 'green'});
            }
            catch (err) {
                this.openToast({ message: this.$t("group.dealer_group_in_use"), duration: 5000, color: 'red'});
            }
        },
        async saveDealerGroup(data) {
            let response = await UserService.saveDealerGroup(data);
            this.SET_DEALER_GROUPS(response.data);
        },
        SET_DEALER_GROUPS(data) {
            console.log('SET_DEALER_GROUPS', data)
            this.dealerGroups = data.dealerGroups;
            if (data.dealers) {
                this.dealers = data.dealers;
                console.log('SETTING DEALERS !', this.dealers)
            }
        },

        updateAvailableDealers(selectedGroup) {
            this.availableDealers = [];
            if (!selectedGroup) {
                return;
            }
            this.dealers.forEach((dealer) => {
              let groupDealer = selectedGroup.dealers.find(
                (d) => d.id == dealer.id
              );
              if (!groupDealer) {
                this.availableDealers.push(dealer);
              }
            })
            console.log(this.availableDealers)
        },
    }
}
</script>

<style scoped>
.active-dealerGroups {
  border-radius: 8px;
  background: rgba(0, 159, 77, 0.1);
  color: #009f4d;
}
.add-pt-btn {
  left: calc(50% - 120px);
  text-transform: none;
}
.no-transform {
  text-transform: none;
}
tr {
  cursor: pointer !important;
}
</style>