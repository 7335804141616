<template>
    <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
        >
        <v-list dense>
            <v-list-item-group>
                <v-list-item @click="renameAction">
                <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ renameName }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteAction">
                <v-list-item-icon>
                    <v-icon color="red">mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ deleteName }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>
<script>

export default {
    name: "RenameDeleteActions",
    props: [
        'value',
        'x',
        'y',
        'renameAction',
        'renameName',
        'deleteAction',
        'deleteName'
    ],
    computed: {
        showMenu: {
            get: function () {
                return this.value;
            },

            set: function(newValue) {
                 this.$emit('input', newValue);
            }
        }
    }
}

</script>
<style scoped>

</style>
