<template>
    <v-dialog
        max-width="500"
        v-model="open"
    >
        <v-card>
            <v-card-title>
                {{ title }}
            </v-card-title>
            <v-card-text>
                {{ description }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="handleCancel()">
                    {{ cancelName }}
                </v-btn>
                <v-btn color="green darken-1" text @click="handleAction()">
                    {{ actionName }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: "ConfirmDialog",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: "Confirmation",
        },
        description: {
            type: String,
            required: true
        },
        cancelName: {
            type: String,
            default: "NO"
        },
        cancelFunction: {
            type: Function,
            required: false
        },
        actionName: {
            type: String,
            default: "YES"
        },
        actionFunction: {
            type: Function,
            required: false
        },
    },
    computed: {
        open: {
            // getter
            get() {
                return this.value
            },
            // setter
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
    methods: {
        closeDialog() {
            this.open = false
        },
        handleCancel() {
            if(this.cancelFunction instanceof Function)
                this.cancelFunction();

            this.closeDialog();
        },
        handleAction() {
            if(this.actionFunction instanceof Function)
                this.actionFunction();

            this.closeDialog();
        },
    }

}
</script>
<style scoped>

</style>
